import React, { useState, useEffect, useRef } from "react";
import { Section, MainComponent, TabsComponent, ContactComponent, ImageComponent, StatisticsComponent, SliderComponent } from "../../components";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import "./styles.scss";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { setRef, articlesList } from "../../actions";
import * as animationData from "../../assets/animations/FightScout-Success-Animationt.json";
import PartnersComponent from "../../components/PartnersComponent/PartnersComponent";
import ModalComponent from "../../components/ModalComponent";
import Modals from "../../components/ModalComponent/Modals";

const Home = () => {
  const imageRef = useRef(null);
  const isMobileVersion = window.innerWidth < 900 ? 1 : 0;
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.blogs.allBlogs);

  const [circlePositions, setCirclePositions] = useState([]);
  const [openModal, setOpenModal] = useState({ open: false, el: null });

  const options = [
    { name: "Fighters", value: "782", profiles: true, role: "fighter" },
    { name: "Promoters", value: "822", profiles: true, role: "promoter" },
    { name: "Managers", value: "422", profiles: true, role: "manager" },
    { name: "Coaches", value: "202", profiles: true, role: "coach" },
    { name: "Fans", value: "403", profiles: true, role: "fan" },
  ];

  const universeSectionData = [
    { img: require("../../assets/images/universe-mobile-1.png"), link: "" },
    { img: require("../../assets/images/universe-mobile-2.png"), link: "/fighter" },
    { img: require("../../assets/images/universe-mobile-3.png"), link: "/manager" },
    { img: require("../../assets/images/universe-mobile-4.png"), link: "/promoter" },
    { img: require("../../assets/images/universe-mobile-5.png"), link: "/coach" },
    { img: require("../../assets/images/universe-mobile-6.png"), link: "/fan" },
  ];

  const dataInnovations = [
    { icon: "innovations-icon-1.svg", title: "Stream and watch events", text: "Promoters can stream their events directly in the app for all users to watch. Fight Scout closes the circle by providing a streaming app and web panel to control all streams.", ref: "stream and watch events" },
    { icon: "innovations-icon-2.svg", title: "Medical records and documents", text: "The app allows fighters to submit the needed documentaion for participation in an event directly to commissioners for certain areas of the world.", ref: "medical records and documents" },
    { icon: "innovations-icon-3.svg", title: "Video meetings", text: "A key feature within the Fight Scout App is the chat option, giving users the ability to schedule and connect with video meetings.", ref: "video meetings" },
    { icon: "innovations-icon-4.svg", title: "Activities", text: "Daily activities can be uploaded making your profile far more interesting to other Fighters, Promoters and Fans.", ref: "activities" },
    { icon: "innovations-icon-5.svg", title: "Custom share", text: "This function allows users to share each profile, event or activity to all other social media platforms with custom look.", ref: "custom share" },
    { icon: "innovations-icon-6.svg", title: "Smart search engine", text: "With endless content, the app has the option for users to do a specific search for all profiles and events by selection one or more of many different criteria and combining them for optimal results.", ref: "smart search engine" },
    { icon: "innovations-icon-7.svg", title: "Switch profiles", text: "We know that ours users wear many hats, that’s why we allow each account to have multiple profiles attach to it.", ref: "switch profiles" },
  ];

  const links = ["/fighter", "/manager", "/promoter", "/coach", "/fan"];

  const calcPositions = (circleIndex) => {
    // let coordinates = { top: top || 0, bottom: bottom || 0, left: left || 0, right: right || 0 }
    if (imageRef?.current === null || !circleIndex) return;

    const imageWidth = imageRef.current.clientWidth;
    const imageHeight = imageRef.current.clientHeight;

    const circles = Array.from({ length: 5 }).fill({ top: 0, right: 0 });

    const calculatedPositions = circles.map((position, index) => {
      let top;
      let right;
      if (index == 0) {
        top = imageHeight * 0.5 + "%"; // calculate X position here using parentWidth, imageWidth, linkRect.width, etc.
        right = imageWidth * 0.43 + "%";
      }

      return { top: top || 0, right: right || 0 };
    });

    setCirclePositions(calculatedPositions);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    dispatch(articlesList({ pageNumber: 1, pageSize: 3, noPagination: true, search: "" }));

    window.addEventListener("resize", calcPositions);

    return () => {
      window.removeEventListener("resize", calcPositions);
    };
  }, []);

  return (
    <div className="home-container">
      {isMobileVersion === 1 ? (
        <Section
          video={"/home.mp4"}
          mobile={isMobileVersion}
          noAlign={1}
          padding
          title="THIS IS THE FIGHT SCOUT APP"
          titleMargin={5}
          reversed
          // fullVideo
          // height={100}
          height={`fit-content`}
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title mb-4">EXPLORE AND CREATE CONNECTIONS</h3>
                  <p className="mb-15">
                    <b className="uppercase">Fight Scout</b>, the first app that allows you to explore and connect to the entire Combat Sports Community around the world. Sign in as a pro and start growing your network.
                  </p>
                </div>
                <br />
                <div className="flex-wrapper wide mb-4">
                  <p className="title">What’s new?</p>
                  <img src={require("../../assets/icons/red-line.svg").default} />
                </div>

                <div className="cards-wrapper-home flex-wrapper wide">
                  {articles?.slice(0, 3)?.map((el, index) => {
                    return (
                      <div className="card-wrapper flex-wrapper column start" key={`card-${index}-${el?._id}`} style={{ backgroundImage: `url(${el?.coverPhoto?.address})` }}>
                        {/* <div className="flex" key={`card-${index}`}> */}
                        <div className="transparent" />
                        <div className="date start z-10 text-white mb-9">{moment(el?.createdAt).format(`DD.MM.YYYY`)}</div>
                        <p className="text mb-4 uppercase">{el?.title}</p>
                        <div className="text underline end red mobile-text" onClick={() => history.push(`article?/${el?._id}`)}>
                          Read more
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          }}
        />
      ) : (
        <Section
          title="THIS IS THE FIGHT SCOUT APP"
          //opacity
          fullVideo
          contentHeight
          height={100}
          titleMargin={window.innerWidth < 1400 ? 1 : window.innerWidth < 1700 ? 15 : -5}
          renderContent={() => {
            return (
              <>
                <div className="line-and-text">
                  <h3 className="lower-title">EXPLORE AND CREATE CONNECTIONS</h3>
                  <br />
                  <p className="mb-15">
                    <b>Fight Scout</b>, the first app that allows you to explore and connect to the entire Combat Sports Community around the world. Sign in as a pro and start growing your network.
                  </p>
                </div>
                <br />
                <div className="flex-wrapper start-row wide mb-12 justify-start">
                  <p className="title">What’s new?</p>
                  <img src={require("../../assets/icons/red-line.svg").default} />
                </div>

                <div className="cards-wrapper-home flex-wrapper wide">
                  {articles?.slice(0, 3)?.map((el, index) => {
                    return (
                      <div className="card-wrapper flex-wrapper column start" key={`article-card-${index}-${el?._id}`} style={{ backgroundImage: `url(${el?.coverPhoto?.address})` }}>
                        {/* <div className="flex" key={`card-${index}`}> */}
                        <div className="transparent" />
                        <div className="date start z-10 text-white mb-10">{moment(el?.createdAt).format(`DD.MM.YYYY`)}</div>
                        <p className="text mb-8 uppercase preview">{el?.title}</p>
                        <div className="text underline end red" onClick={() => history.push(`article?/${el?._id}`)}>
                          Read more
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          }}
        />
      )}
      {/* <TabsComponent /> */}
      <ImageComponent
        title="OUR UNIVERSE"
        backgroundImage={`url("../../assets/images/FightScout-Homepage-Our-universe.png")`}
        center
        blended={isMobileVersion}
        openModal={openModal}
        setOpenModal={setOpenModal}
        renderContent={() => {
          return (
            <>
              <div className="line-and-text padding">
                <h3 className="lower-title uppercase">Choose your role and Tailor your experience</h3>
                <br />
              </div>

              {
                isMobileVersion === 1 ? (
                  <div className="main-image-wrapper image-component-mobile flex flex-col blended gap-5 pt-6">
                    <div className="circles-wrapper flex flex-col items-center w-full justify-between">
                      {universeSectionData.slice(1)?.map((el, index) => {
                        return (
                          <img
                            key={`image-mobile-column-${index}`}
                            className="main-image cursor-pointer"
                            src={el.img}
                            onClick={() => {
                              if (el?.link) history.push(el.link);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  // new
                  <div className="img-links-wrapper" ref={imageRef}>
                    <img className="main-image" style={{ height: "unset" }} src="https://fightscout.s3.eu-central-1.amazonaws.com/images/Our-UniverseIllustration.png" />

                    {links?.map((link, index) => {
                      return (
                        <div
                          className="circle-link"
                          //  style={calcSinglePosition(index)}
                          key={`home-page-article-link-${index}`}
                          id={`circle-link-${index + 1}`}
                          onClick={() => history.push(link)}
                        />
                      );
                    })}
                    <br />
                  </div>
                )
              }
            </>
          );
        }}
      />

      <StatisticsComponent
        title="OUR USERS"
        subtitle="This is what our users are saying"
        options={options}
        noMargin
        registeredUsers
        // height={100}
      />
      {/* innovations */}

      <SliderComponent title="INNOVATIONS" innovations data={dataInnovations} innovationsRef homePage backgroundImage={`url("https://fightscout.s3.eu-central-1.amazonaws.com/images/Our-Users-Innovations-Background.png")`} />

      {isMobileVersion === 1 ? (
        <>
          <Section
            // image = 'https://fightscout.s3.eu-central-1.amazonaws.com/images/Unique-Photo.png'
            image={`url(${require("../../assets/images/Unique-Photo.png")})`}
            // image = {`url(${require("../../assets/images/unique-photo-mobile.png")})`}

            animation={animationData}
            // title="WHAT MAKES IT UNIQUE"
            reversed
            mobile={isMobileVersion}
            title={""}
            // height={101}
            renderContent={() => {
              return (
                <>
                  {/* <img className="w-full" src={require("../../assets/images/unique-photo-mobile.png")} /> */}
                  <h1 className="title font-semiBold mt-3 padding">WHAT MAKES IT UNIQUE</h1>
                  <div className="line-and-text padding">
                    <h3 className="lower-title">CUSTOM ACTIONS THAT CONNECT EVERYONE</h3>
                    <p className="font-light mb-3">
                      Each role allows you to experience a different type of connection. Whether you are a fighter looking for the next event, a promoter recruiting for an event, a manager trying to recruit athletes or a coach looking for new starts to train, <b>Fight Scout</b> has got you covered.
                    </p>
                    <p className="font-light">We haven’t forgot about the backbone of each industry - the fans. They can follow all their favourite pro accounts and track what’s happening in their careers.</p>
                  </div>
                </>
              );
            }}
          />
        </>
      ) : (
        <>
          <Section
            // image = 'https://fightscout.s3.eu-central-1.amazonaws.com/images/Unique-Photo.png'
            image={`url(${require("../../assets/images/Unique-Photo.png")})`}
            animation={animationData}
            title="WHAT MAKES IT UNIQUE"
            reversed
            mobile={isMobileVersion}
            height={101}
            renderContent={() => {
              return (
                <>
                  <div className="line-and-text">
                    <h3 className="lower-title">CUSTOM ACTIONS THAT CONNECT EVERYONE</h3>
                    <br />
                    <p className="font-light">
                      Each role allows you to experience a different type of connection. Whether you are a fighter looking for the next event, a promoter recruiting for an event, a manager trying to recruit athletes or a coach looking for new starts to train, <b>Fight Scout</b> has got you covered.
                    </p>
                    <br />
                    <p className="font-light">We haven’t forgot about the backbone of each industry - the fans. They can follow all their favourite pro accounts and track what’s happening in their careers.</p>
                  </div>
                </>
              );
            }}
          />
        </>
      )}

      {/* {isMobileVersion === 1 ? <ContactComponent mobile={1}/> : <ContactComponent/>} */}
      <ModalComponent open={openModal.open} children={<Modals.InfoModal header={openModal?.el?.title} body={openModal?.el?.text} image={openModal?.el?.logo} handleClose={() => setOpenModal({ open: false, el: null })} />} />
    </div>
  );
};

export default Home;
