/* eslint-disable */
import React, { useEffect } from "react";
import "./styles.scss";

const ModalComponent = ({ children, open, position }) => {
  useEffect(() => {
    if (open && window.innerWidth > 900) {
      document.getElementById("html").style.overflowY = "hidden";
    } else document.getElementById("html").style.overflowY = "auto";
  }, [open]);

  return <div className={`modal-component ${open && "show"} ${position}`}>{children}</div>;
};
export default ModalComponent;
