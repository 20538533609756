import BKFC from "../assets/logos/BKFC.png";
import FNC from "../assets/logos/FNC.png";
import ADCC from "../assets/logos/ADCC.png";
import maxFight from "../assets/logos/MaxFight.png";
import irelandBJJ from "../assets/logos/IrelandBJJ.png";
import emperorMats from "../assets/logos/EmperorOfTheMats.png";
import brazilianBjj from "../assets/logos/BrazilianBJJ.png";
import gamma from "../assets/logos/GAMMA.png";
import bushido from "../assets/logos/Bushido.png";
import jiutopia from "../assets/logos/Jiutopia.png";
import czechBJJ from "../assets/logos/MMAACZ.png";
import bulgarianBBJ from "../assets/logos/BulgariaBJJ.png";
import LBE from "../assets/logos/LBE.png";
import brazilianBjj2 from "../assets/logos/FededationJiu-Jitsu.png";
import cagedSteel from "../assets/logos/CagedSteel.png";
import prospectAmateurs from "../assets/logos/Prospect_Amateurs.png";
import proFight from "../assets/logos/Pro-Fight.png";
import hittron from "../assets/logos/HITTRON.png";
import octagonFighting from "../assets/logos/Octagon_Fighting.png";

export const partners = [
  {
    _id: 1,
    title: "Bare Knuckle Fighting Championship",
    text: `Renowned for bringing back the art of bareknuckle boxing, BKFC is leading promotion in the rule set. Founded by David Feldman 6 years ago, BKFC has recently attracted Conor McGregor as a shareholder. Bare knuckle boxing is said to be the fastest growing combat sport in the world. BKFC promotion stands at the forefront of the industry, offering intense matchups that have captivated a global audience. BKFC has selected Fight Scout to discover new talented athletes from all over the world. In the first two months after the official announcement of the partnership, 6 fighters had already received their offers and are on the path to success.`,
    overview: "BKFC has selected Fight Scout to discover new talented athletes from all over the world.",
    logo: BKFC,
  },
  {
    _id: 2,
    title: "Fight Nation Championship",
    text: `FNC is a premier mixed martial arts promotion hosting electrifying events featuring top-tier fighters in Europe. They partnered with FightScout to streamline their athlete selection process, using our extensive database and advanced filtering tools to find the best contenders for their high-profile events.`,
    overview: "Partnered with FightScout to streamline their athlete selection process using our extensive database and advanced filtering tools.",
    logo: FNC,
  },
  {
    _id: 3,
    title: "Abu Dhabi Combat Club",
    text: `ADCC is the gold standard in submission grappling, with its world championship events drawing the best athletes from various martial arts disciplines to compete on an elite stage. ADCC know for having affiliates in all countries that have freedom to select their streaming partners. This year 4 affiliates chose FightScout to stream their prestigious events due to our user-friendly interface and the platform's capability to handle multiple simultaneous streams, ensuring a seamless viewing experience for fans.`,
    overview: "ADCC chose FightScout to stream their prestigious events due to our user-friendly interface and capabilities",
    logo: ADCC,
  },
  {
    _id: 15,
    title: "Caged Steel",
    logo: cagedSteel,
  },
  {
    _id: 6,
    title: "Emperor Of The Mats",
    text: `A prestigious grappling tournament series, EMPEROR OF THE
MATS gathers elite competitors in a showcase of technique, strength, and endurance. They
collaborate with FightScout to enhance event coverage and to recruit high-level competitors
through our targeted scouting features`,
    logo: emperorMats,
  },
  {
    _id: 16,
    title: "Prospect Amateurs",
    logo: prospectAmateurs,
  },
  {
    _id: 8,
    title: "GAMMA",
    text: `GAMMA is dedicated to the global
development of amateur MMA, offering a platform for athletes to compete, grow, and transition
to professional levels under safe and standardized regulations. GAMMA partners with
FightScout to track and support the progress of emerging talent, using our platform to connect
athletes with opportunities worldwide.`,
    logo: gamma,
  },
  {
    _id: 14,
    title: "FJJRIO",
    text: `: As a key organization in the birthplace
of Brazilian Jiu-Jitsu, FJJRIO promotes the growth and excellence of the sport through events
and education. They use FightScout to manage athlete registrations and to provide exposure to
their events, reaching a broader audience within the BJJ community.`,
    logo: brazilianBjj2,
  },
  {
    _id: 13,
    title: "LBE Fighters Championship",
    text: `LBE is committed to organizing top-tier combat sports events
across Spain, providing a stage for local talent to shine. They chose FightScout to boost event
promotion and athlete engagement, utilizing our platform's extensive reach and community-focused features.`,
    logo: LBE,
  },
  {
    _id: 9,
    title: "MMA Bushido",
    text: `MMA Bushido is a renowned European mixed martial arts promotion, offering
thrilling fight nights that highlight the skills and determination of fighters across the continent.
FightScout aids MMA Bushido in discovering and recruiting emerging European talent, ensuring
their events continue to feature the best fighters the region has to offer.`,
    logo: bushido,
  },
  {
    _id: 5,
    title: "Ireland BJJ",
    text: `A vital part of the Brazilian Jiu-Jitsu community in Ireland, this organization
fosters the sport's growth by hosting events and nurturing talent at both amateur and professional
levels. Ireland BJJ partners with FightScout to streamline event organization and to connect with
athletes across Europe, ensuring strong participation and engagement.`,
    logo: irelandBJJ,
  },
  {
    _id: 17,
    title: "Pro Fight",
    logo: proFight,
  },
  {
    _id: 18,
    title: "HITTRON",
    logo: hittron,
  },
  {
    _id: 4,
    title: "Max Fight Championship",
    text: `As one of Bulgaria's leading MMA promotions, MAXFIGHT delivers actionpacked events featuring a mix of seasoned fighters and upcoming prospects. They partner with
FightScout to improve their athlete recruitment process, ensuring that each event features top-tier
competitors.
`,
    logo: maxFight,
  },
  {
    _id: 12,
    title: "Bulgaria BJJ",
    text: `Dedicated to advancing Brazilian Jiu-Jitsu in Bulgaria, this organization
promotes the sport through competitions, seminars, and community engagement. FightScout
supports Bulgaria BJJ by providing a platform for athlete registration and event promotion,
helping to grow the local BJJ scene.`,
    logo: bulgarianBBJ,
  },
  {
    _id: 7,
    title: "BJJ Federation",
    text: `BJJFP is instrumental in growing
the BJJ community in the Philippines, organizing events and providing resources to support
athletes. They work with FightScout to expand their reach and to connect Filipino athletes with
international opportunities.`,
    logo: brazilianBjj,
  },

  {
    _id: 11,
    title: "Czech MMA Association",
    text: `MMA Chech is a prominent MMA organization known for its commitment to
showcasing top-tier talent. They utilize FightScout to scout new fighters and to ensure their
events feature the most skilled and competitive athletes in the region.`,
    logo: czechBJJ,
  },
  {
    _id: 10,
    title: "Jiutopia BJJ",
    text: `Jiutopia is a unique initiative that blends Brazilian Jiu-Jitsu with a lifestyle brand,
creating events that are as much about community and culture as sports camps. They partner with
FightScout to enhance their event management and to connect with a broader community of BJJ
enthusiasts.`,
    logo: jiutopia,
  },
  {
    _id: 19,
    title: "Octagon Fighting",
    logo: octagonFighting,
  },
];
