import React, { useRef, useState } from "react";
import "./styles.scss";
import { TitleComponent } from "../TitleComponent";
import { partners } from "../../config/constants";
import Slider from "react-slick";

const PartnersComponent = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const partnersRef = useRef();
  const defaultSlickSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const partnersSlickSettings = {
    ...defaultSlickSettings,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
  };

  return (
    <div className="partners-container px-[2%]">
      {window.innerWidth < 900 ? (
        <>
          <div className="flex flex-col w-full justify-center">
            <TitleComponent title={"OUR PARTNERS"} marginleft={2.5} />
            <p className="text-lg">PARTNERS WHO MAKE THIS POSSIBLE</p>
            <div className="flex flex-col w-full gap-3 p-5">
              {" "}
              <div className="flex w-full overflow-x-scroll gap-3 pr-2">
                {partners.slice(0, 3).map((part) => (
                  <div className="border border-[#595667] rounded-md min-w-[85vw] pb-2" style={{ background: "rgba(0, 0, 0, 0.91)", backdropFilter: "blur(20px)" }}>
                    <div className="w-full h-[300px] bg-cover bg-center bg-no-repeat rounded-md" style={{ backgroundImage: `url(${part.logo})` }} />
                    <p className="text-xl font-bold w-full px-3 py-2">{part.title}</p>
                    <p className="px-3 text">{part.overview}</p>
                  </div>
                ))}
              </div>
              <div className="w-full flex gap-3 overflow-x-scroll">
                {partners.slice(3, partners.length).map((part) => (
                  <div className="border border-[#595667] rounded-md pb-2 min-w-[200px]" style={{ background: "rgba(0, 0, 0,1)", backdropFilter: "blur(20px)"}}>
                    <div className="w-full h-[120px] bg-contain bg-center bg-no-repeat rounded-md" style={{ backgroundImage: `url(${part.logo})`, backgroundColor: part.title?.toLowerCase()?.includes("hittron") ? "#cb0c24" : "black"  }} />
                    <p className="font-bold w-full px-3 py-2">{part.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center w-full">
            <div className="flex flex-col h-full w-4/10 justify-center">
              <TitleComponent title={"OUR PARTNERS"} />
              <p className="lg:text-lg md:text-md tablet:text-sm">PARTNERS WHO MAKE THIS POSSIBLE</p>
            </div>
            <div className="flex items-center justify-end w-6/10 gap-3">
              {partners.slice(0, 3).map((part) => (
                <div className="h-[400px] border border-[#595667] rounded-md w-1/3 pb-5" style={{ background: "rgba(0, 0, 0, 0.91)", backdropFilter: "blur(20px)" }}>
                  <div className="w-full h-[200px] bg-contain bg-no-repeat rounded-md" style={{ backgroundImage: `url(${part.logo})`, backgroundPosition: "top center" }} />
                  <p className="xl:text-lg lg:text-lg md:text-md font-bold w-full px-3 py-2">{part.title}</p>
                  <p className="px-3 xl:text-md lg:text-md md:text-xs">{part.overview}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full flex items-center justify-end my-5 gap-4 pr-3">
            <div className={`slide-arrow-left`} onClick={() => partnersRef.current?.slickPrev()} />
            <div className={`slide-arrow-left rotate-180`} onClick={() => partnersRef.current?.slickNext()} />
          </div>
          <Slider {...partnersSlickSettings} ref={partnersRef}>
            {partners.slice(3, partners.length).map((part) => (
              <div className="border border-[#595667] rounded-md h-[220px] !w-[95%]" style={{ background: "rgba(0, 0, 0, 0.61)", backdropFilter: "blur(20px)" }}>
                <div className="w-full h-[150px] bg-[#a52f2f] bg-contain bg-no-repeat rounded-md" style={{ backgroundImage: `url(${part.logo})`, backgroundPosition: "top center", backgroundColor: part.title?.toLowerCase()?.includes("hittron") ? "#cb0c24" : "black" }} />
                <p className="text-md md:text-sm font-bold w-full px-3 pb-3 pt-1">{part.title}</p>
              </div>
            ))}
          </Slider>
        </>
      )}
    </div>
  );
};
export default PartnersComponent;
