import React from "react";
import "../styles.scss";
const InfoModal = ({ handleClose, header, body, image }) => {
  const isMobile = window.innerWidth < 900;
  return (
    <div className={`delete-modal-container p-3 w-8/10 bg-[#020203] ${!isMobile && "h-[350px] overflow-y-hidden"} ${isMobile && "w-[95%]"}`}>
      <div style={{ width: "100%", height: "100%" }}>
        <div className="w-full flex items-center justify-end">
          <div className="w-8 h-8 bg-[#1F1D2B] rounded-md border border-[#595667] flex items-center justify-center">
            <div className="close-icon w-4 h-4" onClick={() => handleClose()} />
          </div>
        </div>
        <div className={`flex ${isMobile && "flex-col overflow-y-auto h-9/10 gap-3 mt-2 px-0"} items-center w-full px-5`}>
          <div className={`bg-cover h-[270px] ${isMobile ? "w-full" : "w-1/5"} bg-center`} style={{ backgroundImage: `url(${image})` }} />
          <div className={`${isMobile ? "w-full pl-1" : "w-4/5 pl-3 mt-5"} `}>
            {" "}
            <h1 className="text-2xl font-bold">{header}</h1>
            <p className={`text-sm my-5 ${isMobile && "!p-0"}`}>{body}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
